/* .react-pattern-lock__pattern-wrapper.error .react-pattern-lock__point-wrapper.selected .react-pattern-lock__point .react-pattern-lock__point-inner {
  background: black !important;
} */

/* Circle selected background colour. */
.react-pattern-lock__pattern-wrapper .react-pattern-lock__point-wrapper[data-index='0'] .react-pattern-lock__point .react-pattern-lock__point-inner {
  background: #ffffff !important;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
}

/* Circle selected background colour. */
.react-pattern-lock__pattern-wrapper .react-pattern-lock__point-wrapper.selected .react-pattern-lock__point .react-pattern-lock__point-inner {
  background: #ffffff !important;
  animation: none;
}

/* Circle background colour. */
.react-pattern-lock__pattern-wrapper .react-pattern-lock__point-wrapper .react-pattern-lock__point .react-pattern-lock__point-inner {
  background: #bf9683 !important;
}

/* The dot inside the circle. */
.react-pattern-lock__pattern-wrapper .react-pattern-lock__point-wrapper .react-pattern-lock__point .react-pattern-lock__point-inner::after {
  content: '';
  display: inline-block;
  position: relative;
  left: 10px;
  top: -3px;
  width: 5px;
  height: 5px;
  -moz-border-radius: 2.5px;
  -webkit-border-radius: 2.5px;
  border-radius: 2.5px;
  background-color: #ffffff;
}

/* The line colour connecting the circles. */
.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__connector-wrapper .react-pattern-lock__connector,
.react-pattern-lock__pattern-wrapper.disabled .react-pattern-lock__connector-wrapper .react-pattern-lock__connector {
  background: #ffffff !important;
}

.react-pattern-lock__pattern-wrapper.disabled {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
